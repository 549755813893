<template>
    <div class="d-price-table">
        <template v-if="isLoading">
            <BasePreloaderSkeleton
                class="d-price-table__loader is-header"
            />
            <BasePreloaderSkeleton
                class="d-price-table__loader is-body"
            />
            <BasePreloaderSkeleton
                class="d-price-table__loader is-footer"
            />
        </template>
        <template v-else>
            <PriceTableActions
                :total-count="parseInt(totalCount)"
                :sides="summaryData.slice(2)"
                :is-loading-export="isLoadingExport"
                @onClickAction="onClickAction"
            />
            <kit-budget-tab
                :isProgressBarLoading="isProgressBarLoading"
                :canCheck="true"
                :columns="tableColumns"
                :modifieredCols="tableColumnsModifered"
                :datesCols="tableColumnsDates"
                :tableData="tableRows"
                :folderId="kitId"
                :rowsCheckboxes="tableRowsPricesSelected"
                :rowsSelected="selectedRows"
                class="d-price-table__kit"
                @onCheckRow="onCheckRow"
                @onCheckRowsAll="onCheckRowsAll"
                @filter="onFilter"
                @sort="onSort"
                @changeSinglePrice="onChangeSinglePrice"
                @changePeriod="onChangePeriod"
                @onChangeNds="onChangeNds"
                @onChangeTableColumns="changeTableColumns"
                @lazyload="onLazyLoadTable"
                @onCheckSubCheckbox="onCheckSubCheckbox"
                @onCheckDate="onCheckDate"
            />
            <SelectionBar
                v-if="selectedRowsCount > 0"
                class="d-price-table__selection-bar"
                :scheme="schemeActionsSelectionBar"
                :count="selectedRowsCount"
                @onClickAction="onClickAction"
                @onSelectAll="onCheckRowsAll(true)"
            />
            <div class="d-price-table__summary">
                <div v-if="summaryData[0]" class="d-price-table__summary-item">
                    {{ `${summaryData[0].title}: ${summaryData[0].value}` }}
                </div>
                <div v-if="summaryData[0]" class="d-price-table__summary-item">
                    {{ `${summaryData[1].title}: ${summaryData[1].value}` }}
                </div>
            </div>
            <ExportTemplate
                class="d-price-table__modal-export"
                v-if="isExporting"
                :userTemplates="userTemplates"
                :currentTemplate="currentTemplate"
                :is-loading-export="isLoadingExport"
                @onGetTemplates="onGetTemplates"
                @onCloseExportFolder="onCloseExportFolder"
                @onSaveTemplate="onSaveTemplate"
                @onCheckTemplate="onCheckTemplate"
                @onExportTemplate="onExportTemplate"
                @onRemoveTemplate="onRemoveTemplate"
            />
            <ModalCreateOffers
                class="d-price-table__modal-offers"
                v-if="isCreateOfferPopupOpen"
                title="Создание предложения Oohdesk"
                :tags="offerTags"
                @onCloseModal="onCloseCreateOfferPopup"
                @send="sendFolder"
            />
        </template>
    </div>
</template>

<script>
import PriceTableActions from "@/components/pages/price/PriceTableActions.vue";
import SelectionBar from "@/components/Tables/SelectionBar.vue";
import MixinTableFound from "@/mixins/MixinsPageBayuerdesk/MixinTableFound";
import KitBudgetTab from "@/components/HandbookComponents/KitBudgetTab.vue";
import ServiceApi from "@/services/ServiceApi";
import MixinApi from "@/mixins/MixinsPageBayuerdesk/MixinApi";
import ServiceEditPricesTable from "@/services/ServicesEditPrices/ServiceEditPricesTable";
import ServiceKit from '../../../services/ServicesKit/ServiceKit';
import {mapActions, mapGetters, mapMutations} from "vuex";
import MixinSummary from "@/mixins/MixinSummary";
import MixinKitPanel from "@/mixins/MixinsPageBayuerdesk/MixinKitPanel";
import ExportTemplate from "@/components/Export/ExportTemplate.vue";
import MixinExport from "@/mixins/MixinExport";
import ModalCreateOffers from "@/components/MapV2/components/CreateModal/CreateModal.vue";
import BasePreloaderSkeleton from "@/components/Base/BasePreloaderSkeleton.vue";
import lodash from "lodash";
import UtilDate from '../../../utils/UtilDate.js';

export default {
    name: "PriceTable",
    components: {BasePreloaderSkeleton, ModalCreateOffers, ExportTemplate, KitBudgetTab, SelectionBar, PriceTableActions},
    emits: [],
    mixins: [
        MixinTableFound,
        MixinApi,
        MixinSummary,
        MixinKitPanel,
        MixinExport,
    ],
    props: {
        kitId: {
            type: String,
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        isProgressBarLoading: true,
        isCreateOfferPopupOpen: false,
        offerTags: [],
    }),
    computed: {
        ...mapGetters('editPricesModificationsStore', ['modificationsList', 'modificationsCount', 'isModificationsListChanged']),
        ...mapGetters('editPriceFiltersStore', ['activeMode', 'isUncover', 'filtersSelected', 'totalCount']),
        ...mapGetters('kitsStore', ['findKitById']),
        schemeActionsSelectionBar() {
            let result = [
                {
                    key: 'unselect-all',
                    label: "Сбросить выбор",
                    iconComponentName: "IconSelectAll",
                },
                // {
                //     key: 'export-selected',
                //     label: "Скачать",
                //     iconComponentName: "IconDownload",
                // },
                {
                    key: 'remove-selected',
                    label: 'Удалить',
                    iconComponentName: "IconDelete"
                }
            ];

            return result;
        },
        tabModeIdActive() {
            return this.activeMode === 'period_uncover' ? 'month' : this.activeMode;
        },
        /**
         * Выбранные цены набора
         * @returns {Object}
         */
        tableRowsPricesSelected() {
            let tableRowsPricesSelected = {};
            this.tableRows.forEach(row => {
                const {id = '-1', prices = []} = row;
                tableRowsPricesSelected[id] = prices.map(price => {
                    const {date = ''} = price;
                    return date;
                })
            });
            return tableRowsPricesSelected;
        },
        /**
         * Тело запроса для таблицы
         * @returns {Object}
         */
        tableRequestBody() {
            const tableRequestBody = {
                set_id: this.kitId,
                sort: this.tableSort,
                filter: this.tableFilter,
                ...this.filtersSelected,
                pagination: {
                    per_page: parseInt(this.tableFoundPerPage),
                    page: parseInt(this.tableFoundPageNumber)
                }
            };
            return tableRequestBody;
        },
    },
    async mounted() {
        try {
            const rows = await this.postTableBudgetDataCustomKit();
            await this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
            await this.fetchSelectedTableRows();

            this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
            this.setTableCustomRows(this.tableRows);

            await this.postSummaryData();

            this.totalRows = parseInt(this.totalCount) || 0;
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            this.isProgressBarLoading = false;
        }
    },
    watch: {
        async activeMode() {
            await this.afterPostTableBudgetData(this.tableRowsFromApi, 'prices', true, this.tabModeIdActive, this.isUncover);
        },
        async filtersSelected() {
            await this.fetchSetSelectedTableRows('remove-all', undefined, { set_id: this.kitId, ...this.filtersSelected });
            this.tableFoundPageNumber = 1;
            const rows = await this.postTableBudgetDataCustomKit();
            await this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
            this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
            this.setTableCustomRows(this.tableRows);
            await this.postSummaryData();
        },
        totalCount() {
            this.totalRows = parseInt(this.totalCount) || 0;
        },
        tableRows() {
            const dates = this.tableRows.reduce((dates, tableRow) => {
                const tableRowDates = tableRow?.prices.map(price => String(price?.date || ''));
                const datesDifference = lodash.difference(tableRowDates, dates);
                dates.push(...datesDifference);

                return dates;
            }, []);

            this.setAccessedDates(dates);
        },
        async isModificationsListChanged() {
            if (this.isModificationsListChanged === true) {
                this.tableFoundPageNumber = 1;
                const rows = await this.postTableBudgetDataCustomKit();
                await this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
                await this.postSummaryData();
            }
        }
    },
    methods: {
        ...mapMutations('kit', ['setTableCustomPricesSelected', 'setTableCustomRows']),
        ...mapMutations('editPricesModificationsStore', ['addModification']),
        ...mapMutations('editPriceFiltersStore', ['setSelectedRows', 'setAccessedDates', 'setIsChangingMode', 'setFiltersSelected', 'setTotalCount']),
        ...mapActions('editPriceFiltersStore', ['fetchFiltersData']),
        ...mapMutations('history', ['addHistoryItem']),
        async onCheckDate(dates = [], slotData = {}) {
            const selectDates = dates.map(date => UtilDate.fromDatepickerDate(date));
            const pricesToRemove = slotData?.row?.prices.filter(price => !selectDates.includes(String(price?.date ?? ''))).map(price => String(price?.id ?? ''));
            const pricesToAdd = slotData?.row?.allPrices.filter(price => selectDates.includes(String(price?.date ?? ''))).map(price => String(price?.id ?? ''));

            if (pricesToAdd.length)
                await this.fetchSelectDates(pricesToAdd, 'add');

            if (pricesToRemove.length)
                await this.fetchSelectDates(pricesToRemove, 'remove');

            if (pricesToRemove.length || pricesToAdd.length)
                await this.afterFetchSelectDates()
            
        },
        async fetchSelectDates(pricesIds = [], action = '') {
            const requestBody = {
                set_id: this.kitId,
                ids: [...pricesIds],
                action
            };

            await this.postSyncDataCustom(requestBody);
        },
        async afterFetchSelectDates() {
            this.tableFoundPageNumber = 1;
            const rows = await this.postTableBudgetDataCustomKit();
            await this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
            await this.postSummaryData();
        },
        onCheckSubCheckbox(checkData = {}) {
            const columnKey = String(checkData?.col ?? '');
            const date = columnKey.split('__')[0] ?? '';
            const allPrices = checkData?.row?.allPrices ?? [];
            const allPrice = allPrices.find(price => String(price?.date ?? '') === date) ?? {};
            const priceId = String(allPrice?.id ?? '');
            const prices = checkData?.row?.prices ?? [];
            const pricesIds = prices.map(price => String(price?.id ?? ''));
            const action = pricesIds.includes(priceId) ? 'remove' : 'add';

            const requestBody = {
                set_id: this.kitId,
                ids: [priceId],
                action
            };
            this.postSyncDataCustom(requestBody, async () => {
                this.tableFoundPageNumber = 1;
                const rows = await this.postTableBudgetDataCustomKit();
                await this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
                await this.postSummaryData();
            });
        },
        onClickAction(key) {
            if (key === 'select-all') {
                this.onCheckRowsAll(true);
            }

            if (key === 'unselect-all') {
                this.onCheckRowsAll(false);
            }

            if (key === 'remove-selected') {
                this.onRemoveSides();
            }

            if (key === 'export') {
                this.onExportFolder(this.kitId);
            }

            if (key === 'remove') {
                this.onRemoveTab();
            }

            if (key === 'send') {
                this.openCreateOfferPopup();
            }
        },
        async openCreateOfferPopup() {
            this.isCreateOfferPopupOpen = true;
            const {data} = await ServiceApi.get('free', '/new_map/offer-tags');

            this.offerTags = data.data.map(item => ({id: item, value: item})) || []
        },
        onCloseCreateOfferPopup() {
            this.isCreateOfferPopupOpen = false
        },
        sendFolder(sendingEmail = '', selectedOfferTags = []) {
            this.postSendKit(sendingEmail, selectedOfferTags);
            this.onCloseCreateOfferPopup();
        },
        async postSendKit(sendingEmail = '', selectedOfferTags = []) {
            const foundKit = this.findKitById(this.kitId);
            const message = await ServiceKit.sendKit(this.kitId, String(foundKit?.name ?? ''), sendingEmail, selectedOfferTags, this.filtersSelected);

            if (message) {
                // alert(message);

                this.$notify.dNotify({
                    type: 'success',
                    title: 'Набор успешно отправлен',
                    description: message,
                    duration: Infinity,
                    position: 'bottom-right'
                });
            }
        },
        /**
         * Событие удаления набора
         */
        async onRemoveTab() {
            // const response = confirm("Вы уверены?")

            // if (response) {
            //     this.postRemoveKit(this.kitId, this.afterPostRemoveKitAndSetActive);
            //     this.$router.push({name: 'bayuerdeskV2'});
            // }

            const data = await this.postRemoveKit(this.kitId, () => {}, this.filtersSelected);
            
            if (data?.data?.is_removed)
                this.$router.push({name: 'bayuerdeskV2'});
            else {
                this.setFiltersSelected({});
                this.fetchFiltersData(this.kitId);
            }
        },
        /**
         * Получение списка шаблонов
         * @returns {Promise<void>}
         */
        async onGetTemplates() {
            this.postTemplateList((templates) => {
                this.afterPostTemplateList(templates);
            });
        },
        /**
         * Сохранение шаблона
         * @param toSaveTemplate
         * @returns {Promise<void>}
         */
        async onSaveTemplate(toSaveTemplate) {
            this.postTemplateSave(toSaveTemplate, (savedTemplate) => {
                this.afterPostTemplateSave(toSaveTemplate, savedTemplate);
            });
        },
        /**
         * Удаление шаблона
         * @returns {Promise<void>}
         */
        async onRemoveTemplate() {
            this.postTemplateRemove(this.currentTemplate.id, () => {
                this.afterPostTemplateRemove();
            });
        },
        /**
         * Экспорт шаблона
         * @returns {Promise<void>}
         */
        // async onExportTemplate() {
        //     this.isLoadingExport = true;
        //     let bodyPost = {set_id: this.exportingSetId};
        //     if (this.currentTemplate.id > 0) {
        //         bodyPost.template_id = this.currentTemplate.id;
        //     }
        //     await this.postExportKit(bodyPost, (link) => {
        //         this.afterPostExportKit(link);
        //     });
        //     this.isLoadingExport = false;
        // },
        async onExportTemplate(isRemoveDoubles){
            if(this.exportingSetId === null){
                // alert('Что то пошло не так');

                this.$notify.dNotify({
                    type: 'error',
                    title: 'Ошибка экспорта шаблона',
                    description: 'Что-то пошло не так',
                    duration: Infinity,
                    position: 'bottom-right'
                });
            }
            let bodyPost = {
                set_id: this.exportingSetId,
                is_double_sides: !isRemoveDoubles,
                set_filter: { ...this.filtersSelected }
            };
            if(this.currentTemplate.id > 0){
                bodyPost.template_id = this.currentTemplate.id;
            }
            this.postExportKitQueue(bodyPost, (history, isTask) => {
                if(isTask){
                    this.$notify.dNotify({
                        type: 'success',
                        title: 'Задача на экспорт успешно добавлена в очередь',
                        description: 'Создание файла может занять некоторое время. Пожалуйста, подождите',
                        duration: Infinity,
                        position: 'bottom-right'
                    });
                }

                const {status = 0, url = null} = history;
                if(Number(status) === 2 && url !== null){
                    this.afterPostExportKit(url);
                }
                this.addHistoryItem(history);
            }, (errorMsg = '') => {
                if (errorMsg) {
                    this.$notify.dNotify({
                        type: 'error',
                        title: 'Не удалось создать задачу на выполнение экспорта',
                        description: errorMsg,
                        duration: Infinity,
                        position: 'bottom-right'
                    });
                }
            });
        },
        /*
         * Удаление выбранных элементов из таблицы
         */
        onRemoveSides() {
            const response = confirm("Вы уверены что хотите удалить конструкции из набора?")
            if (!response) {
                return;
            }
            const postData = {set_id: this.kitId, action: 'remove-sides', ids: this.selectedRows};
            this.$emit('onLoading', true);
            this.postSyncDataCustom(postData, async () => {
                await ServiceEditPricesTable.setSelectedTableRows("remove", this.selectedRows, {
                    set_id: this.kitId
                }, this.tableFilter);
                this.tableRows = this.tableRows.filter(item => !this.selectedRows.includes(item.id));
                this.selectedRows = [];
                this.setTableCustomPricesSelected(this.selectedRows);
                this.setTableCustomRows(this.tableRows);
                await this.postSummaryData();
            });
        },
        /**
         * Событие выбора цены в наборе
         * @property {Object} checkData - данные выбора
         */
        async onCheckRow(checkData = {}) {
            this.selectedRows = checkData.rowsIdsSelected;
            this.setSelectedRows(this.selectedRows);
            try {
                await ServiceEditPricesTable.setSelectedTableRows(!checkData?.rowIsSelected ? "remove" : "add", [parseInt(checkData.row.id)], {
                    set_id: this.kitId,
                    ...this.filtersSelected
                }, this.tableFilter);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        /**
         * Событие выбора всех цен в наборе
         * @property {boolean} isSelected
         */
        async onCheckRowsAll(isSelected = false) {
            try {
                const response = await ServiceEditPricesTable.setSelectedTableRows(isSelected ? "select-all" : "remove-all", undefined, {
                    set_id: this.kitId,
                    ...this.filtersSelected
                }, this.tableFilter);
                this.selectedRows = response;
                this.setSelectedRows(response);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        async fetchSetSelectedTableRows(type = '', ids = [], filter = {}) {
            try {
                const response = await ServiceEditPricesTable.setSelectedTableRows(type, ids, filter, this.tableFilter);
                this.selectedRows = response;
                this.setSelectedRows(response);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        async fetchSelectedTableRows() {
            try {
                const {data = {}} = await ServiceApi.get('free', `/map/set-selected/${this.kitId}`)
                const {data: rows} = data;
                this.selectedRows = [...rows];
                this.setSelectedRows(this.selectedRows);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        async onLazyLoadTable() {
            if (this.tableFoundPageNumber >= this.tableTotalPages) return;
            if (this.isProgressBarLoading === true) return;

            this.isProgressBarLoading = true;
            this.tableFoundPageNumber += 1;
            const rows = await this.postTableBudgetDataCustomKit();
            this.afterPostTableBudgetData(rows, 'prices', false, this.tabModeIdActive, this.isUncover);
            this.isProgressBarLoading = false;
        },
        /**
         * Запрос на получение табличных данных наора
         * @return {Promise<Array>} - массив rows
         */
        async postTableBudgetDataCustomKit() {
            try {
                const res = await ServiceApi.post('free', '/map/set-table-budget', {
                    ...this.tableRequestBody,
                });
                const {data = {}} = res;
                const {data: dataCustom = {}} = data;
                const {rows = []} = dataCustom;
                let rowsBuffer = [...rows];
                this.tableRowsFromApi = [...rowsBuffer];
                return rowsBuffer;
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * Событие смены флага ндс
         * @param {String} id - id строки
         * @param {Boolean} value - значение флага
         */
        onChangeNds(id = '', value = false) {
            const postData = {side_ids: [id], attribute: 'tax', value: value ? 1 : 0};
            this.postTableEditSide(postData, (dataPrices) => {
                this.updateRowsNds(id, value, dataPrices);
            });
        },
        /**
         * Обновить строки после обновления флага НДС
         * @param {String} id - id строки
         * @param {Boolean} value - значение флага
         * @param {Array} pricesNds - цены в соответствии с флагом НДС
         */
        updateRowsNds(id = '', value = false, pricesNds = []) {
            const rowIndex = this.tableRows.findIndex(row => {
                const {id: rowId = '-1'} = row;
                return String(rowId) === String(id);
            });
            if (rowIndex !== -1) {
                const pricesNdsIds = pricesNds.map(priceNds => {
                    const {id = ''} = priceNds;
                    return String(id);
                });
                const {prices: pricesRow = []} = this.tableRows[rowIndex] || {};
                const pricesRowNew = pricesRow.map(priceRow => {
                    let priceRowNew = {...priceRow};
                    const {id = ''} = priceRow;
                    if (pricesNdsIds.includes(String(id))) {
                        const priceNds = pricesNds.find(priceNds => {
                            const {id: idPriceNds = ''} = priceNds;
                            return String(idPriceNds) === String(id);
                        });
                        const {price: priceNdsValue = ''} = priceNds;
                        priceRowNew = {
                            ...priceRowNew,
                            price: String(priceNdsValue)
                        };
                    }
                    return priceRowNew;
                });
                this.tableRows.splice(rowIndex, 1, {
                    ...this.tableRows[rowIndex],
                    tax: value ? 1 : 0,
                    prices: pricesRowNew
                });
                this.afterPostTableBudgetData(this.tableRows, 'prices', true, this.tabModeIdActive, this.isUncover);
            }
        },

        async onChangePeriod(dateRange = [], rowId = '') {
            try {
                await this.changePeriod(dateRange, [rowId], this.kitId);
            } catch (error) {
                this.afterPostTableBudgetData(this.tableRowsFromApi, 'prices', true, this.tabModeIdActive, this.isUncover);
                throw error;
            }
        },
        async changePeriod(dateRange = [], rowIds = [], kitId = '') {
            try {
                const modification = await ServiceEditPricesTable.setChangePeriod(dateRange, rowIds, kitId, {
                    set_id: kitId,
                    ...this.filtersSelected
                });
                this.tableFoundPageNumber = 1;
                const rows = await this.postTableBudgetDataCustomKit();
                await this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
                this.addModification(modification);
                await this.postSummaryData();
            } catch (error) {
                // console.log(error);
                if (error.errorMsg) {
                    this.$notify.dNotify({
                        type: 'error',
                        title: 'Ошибка модификации периода',
                        description: error.errorMsg,
                        duration: Infinity,
                        position: 'bottom-right'
                    });
                }
                throw error;
            }
        },

        async postSummaryData() {
            try {
                const response = await ServiceApi.post('free', '/map/set-summary', {
                    set_id: this.kitId,
                    ...this.filtersSelected,
                    table_filter: [...this.tableFilter]
                });
                const result = response.data.data
                this.summaryData = this.AdapterSummaryData(result, false);
                this.setTotalCount(result?.total?.side_count);
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * Событие фильтрации по столбцам
         * @param {Object} dataFilter - данные фильтра по столбцам
         */
        async onFilter(dataFilter = {}) {
            this.beforeFilterTable(dataFilter);
            try {
                this.tableFoundPageNumber = 1;
                const rows = await this.postTableBudgetDataCustomKit();
                await this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
                await this.postSummaryData();

            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        /**
         * Событие сортировки
         * @param {Object} dataSort - данные сортировки
         */
        async onSort(dataSort = {}) {
            this.beforeSortTable(dataSort);
            try {
                this.tableFoundPageNumber = 1;
                const rows = await this.postTableBudgetDataCustomKit();
                await this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);

            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        /**
         * Редактирование ячейки
         * @param {Object} data
         */
        onChangeSinglePrice(data) {
            this.postSetPriceModificationCreate(async (modification) => {
                this.tableFoundPageNumber = 1;
                const rows = await this.postTableBudgetDataCustomKit();
                await this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);

                this.addModification(modification);
                await this.postSummaryData();
            }, {
                set_id: this.kitId,
                dates: data.dates,
                selected: data.selected,
                change_type: 'edit',
                value_type: 'number',
                change_value: data.changeValue,
                change_attribute: data.changeAttribute
            })
        },
        /**
         * Запрос на применение модификаций к выбранным ценам
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         * @param {Object|Boolean} outData
         */
        async postSetPriceModificationCreate(actionAfter = () => {
        }, outData = false) {
            if (outData === false) {
                outData = this.addSetPriceRequestBody;
            }
            try {
                const res = await ServiceApi.post('free', '/map/set-add-modification', outData);
                const {data = {}} = res;
                // const {data: dataCustom = {}} = data;
                // const {rows = [], modification = {}} = dataCustom;
                // actionAfter(rows, modification);

                const {data: modification = {}} = data;
                actionAfter(modification);
            } catch (error) {
                if (error?.response?.data?.data?.set_id[0]) {
                    // alert(error?.response?.data?.data?.set_id[0]);

                    this.$notify.dNotify({
                        type: 'error',
                        title: 'Ошибка при создании модификации',
                        description: error?.response?.data?.data?.set_id[0],
                        duration: Infinity,
                        position: 'bottom-right'
                    });
                }

                console.log(error);
            }
        },
        /**
         * Тело запроса для применения модификаций к выбранным столбцам
         * @returns {Object}
         */
        addSetPriceRequestBody() {
            const setPriceRequestBody = {
                set_id: this.id,
                dates: this.selectedDates,
                selected: this.selectedRows,
                change_type: this.setModificationData.changeType,
                value_type: this.setModificationData.valueType,
                change_value: this.setModificationData.changeValue,
                change_attribute: this.priceOptionSelected,
                filtered: {
                    set_id: this.id,
                    ...this.filtersSelected
                }
            };
            return setPriceRequestBody;
        },
    }
}
</script>

<style scoped lang="scss">
.d-price-table {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--d-color--white);
    border-radius: var(--d-border-radius--2xs);
    overflow: hidden;

    &__kit {
        flex: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;
        ::v-deep .all-table {
            margin: 0;
        }
    }

    &__selection-bar {
        margin: 16px 4px 0;
    }

    &__summary {
        padding: 9px 16px;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;

        &-item {
            color: var(--d-color--text-primary);
            font-size: var(--d-font-size--sm);
            font-weight: var(--d-font-weight--regular);
            line-height: 1;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }

    &__modal {
        &-export,
        &-offers {
            padding: 35px;
        }
    }

    &__loader {
        position: relative;
        margin: 10px;
        width: calc(100% - 20px);
        &.is-header {
            height: 32px;
        }
        &.is-body {
            flex: 1;
        }
        &.is-footer {
            height: 32px;
        }
    }
}
</style>
